
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'

export default defineComponent({
  name: 'TmDropdownSelect',
  components: { TmDropdown, TmDropdownItem },
  props: {
    modelValue: {
      type: [String, Object],
    },
    options: {
      type: Array,
    },
    emitValue: {
      type: Boolean,
    },
    mapOptions: {
      type: Boolean,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
  },
  emits: ['update:modelValue', 'update:dropdown'],
})
